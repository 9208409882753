export default class VimeoButton {
  constructor(id) {
    this.DOM = { el: document.getElementById(id) }
    this.DOM.button = this.DOM.el.querySelector("button.vimeo")
    this.DOM.popup = this.DOM.el.querySelector(".video-popup")
    this.DOM.close = this.DOM.el.querySelector(".vimeo-close")

    this.addEventListeners()
  }

  add() {
    this.DOM.popup.classList.add("vimeo-open")
  }

  remove() {
    this.DOM.popup.classList.remove("vimeo-open")
  }

  addEventListeners() {
    this.DOM.button.addEventListener("click", this.add.bind(this))
    this.DOM.close.addEventListener("click", this.remove.bind(this))
  }
}
